import * as React from 'react'
import * as z from 'zod'
import { Control, FieldValues } from 'react-hook-form'
import { FN, Handler } from '@digital-magic/ts-common-utils'

export const AllValueSchema = z.literal('ALL')
export type AllValue = z.infer<typeof AllValueSchema>

export type FileFieldType = Array<File> | undefined

export type CommonControlProps = Readonly<{
  disabledControls?: boolean
}>

export type CommonModalProps = Readonly<{
  open: boolean
  onClose: () => void
}>

export type ClassNameProps = Readonly<{
  className?: string
}>

export type CommonComponentProps = ClassNameProps & React.PropsWithChildren

export type TitledContainerProps = Readonly<{
  title?: string
}> &
  CommonComponentProps

export type ContainerIdProps = Readonly<{
  containerId: string
}>

export type CommonFormProps<T = void, R = T> = Readonly<{
  onSubmitSuccess: Handler<R> | FN<R, Promise<void>>
  defaultValues?: Partial<T>
}> &
  CommonControlProps

export type CommonFormPropsWithChildren<T = void> = CommonFormProps<T> &
  Readonly<{
    children: (disabledControls?: boolean) => React.ReactNode
  }>

export type ModalFormProps<T = void> = CommonFormProps<T> & CommonModalProps

export type ControlledFormProps<TFieldValues extends FieldValues = FieldValues> = Readonly<{
  control: Control<TFieldValues>
}> &
  CommonControlProps
